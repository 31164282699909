import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Observable, ReplaySubject, timer } from 'rxjs';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders} from '@angular/common/http';
@Injectable(
    { providedIn: 'root'}
)

export class SettingsService {
    settingsSubject = new ReplaySubject();
    settingsObservable = this.settingsSubject.asObservable();
    private settingsList = [];

    constructor(private apiSrvc: ApiService) {}

    // It is to get the left side menu list
    fetchSettingMenuList(): Observable<any> {
        return this.apiSrvc.localrequest('GET', 'assets/settingsmenu.json');
    }

    storeSettingsList(data): any {
        this.settingsList = data;
    }

    retrieveSettingsList(): any {
        return this.settingsList;
    }

    // History Methods
    fetchUsersList(): Observable<any> {
        return this.apiSrvc.apiRequest('GET', '/assets/users.json');
    }
    fetchChannels(): Observable<any> {
        return this.apiSrvc.apiRequest('GET', '/assets/channels.json');
    }

    fetchUserHistory(): void {}

    // Schedules APIs

    // schedulesApis(crudType, data): Observable<any> {
    //     switch (crudType) {
    //         case 'List':
    //             return this.apiSrvc.apiRequest('GET', '/schedules');
    //             break;
    //         case 'Add':
    //             return this.apiSrvc.apiRequest('POST', '/schedules', data);
    //             break;
    //         case 'Delete':
    //             return this.apiSrvc.apiRequest(
    //                 'DELETE',
    //                 `/schedules/${data.id}`
    //             );
    //             break;
    //         case 'Edit':
    //             return this.apiSrvc.apiRequest(
    //                 'PUT',
    //                 `/schedules/${data.id}`,
    //                 data
    //             );
    //             break;
    //     }
    // }

    // usersApis(crudType, data): Observable<any> {
    //     switch (crudType) {
    //         case 'List':
    //             return this.apiSrvc.apiRequest('GET', '/users');
    //             break;
    //         case 'Add':
    //             return this.apiSrvc.apiRequest('POST', '/users', data);
    //             break;
    //         case 'Delete':
    //             return this.apiSrvc.apiRequest('DELETE', `/users/${data.id}`);
    //             break;
    //         case 'Edit':
    //             return this.apiSrvc.apiRequest(
    //                 'PUT',
    //                 `/users/${data.id}`,
    //                 data
    //             );
    //             break;
    //     }
    // }
    // Get resolutions list
    getResolutionsList(): any {
        return this.apiSrvc
            .apiRequest('GET', '/hq-image-resolutions', {}, false, {})
            .toPromise();
    }
    addOrEditChannel() {
        return this.apiSrvc
            .apiRequest('GET', '/channel', {}, false, {})
            .toPromise();
    }
    settingsCrudAPI(crudType, APIname, data, params, headers?:HttpHeaders): Observable<any> {
        switch (crudType) {
            case 'List':
                return this.apiSrvc.apiRequest(
                    'GET',
                    `/${APIname}`,
                    {},
                    false,
                    params
                );
                break;
            case 'ListById':
                return this.apiSrvc.apiRequest(
                    'GET',
                    `/${APIname}/${data.id}`,
                    {},
                    false,
                    params
                );
                break;
            case 'Add':
                return this.apiSrvc.apiRequest(
                    'POST',
                    `/${APIname}`,
                    data,
                    false,
                    params
                );
                break;
            case 'Delete':
                return this.apiSrvc.apiRequest(
                    'DELETE',
                    `/${APIname}/${data.id}`,
                    {},
                    false,
                    params
                );
                break;
            case 'Edit':
                let id = '';
                if (APIname === 'channels' || APIname === 'import-logmatch') {
                    id = params.id;
                    delete params.id;
                }
                return this.apiSrvc.apiRequest(
                    id ? 'POST' : 'PUT',
                    `/${APIname}/${data.id ? data.id : id}`,
                    data,
                    false,
                    params
                );
                break;
        }
    }

    checkHostUrl(): AsyncValidatorFn {
        return (
            control: AbstractControl
        ): Observable<{ [key: string]: any } | null> => {
            return this.searchUrl(control.value);
        };
    }

    searchUrl(text: any): any {
        return timer(500).pipe(
            switchMap(() => {
                return this.apiSrvc
                    .apiRequest('GET', '/hosts/validateURL?host_url=' + text)
                    .pipe(
                        map((res) => {
                            return res.message === 'valid URL' ||
                                res.message === 'host URL already exists'
                                ? null
                                : { invalidURL: true };
                        })
                    );
            })
        );
    }

    fetchHostGroups(): Observable<any> {
        return this.apiSrvc.apiRequest('GET', '/hosts-group', {}, false);
        // return of(HOST_GROUP);
    }


}
